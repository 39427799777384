import { AuthPlugin } from "@/plugins/VueAuthPlugin";
import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLogger from "vuejs-logger";
import vuetify from "../plugins/VuetifyPlugin";
import "../registerServiceWorker";
import router from "../router/report.simple.router";
import store from "../store";
import CheckMyCrash from "./CheckMyCrash.vue";
import { UIHelper } from "../lib/UIHelper";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

// link for toast-notification doc: https://maronato.github.io/vue-toastification/
const toastOptions = {
  position: "bottom-right"
};

Vue.use(Toast, toastOptions);

Vue.use(VueLogger as any, options);

Vue.use(AuthPlugin, {});

Vue.use(UIHelper);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(CheckMyCrash)
}).$mount("#report");
